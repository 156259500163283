.success {
  text-align: center;

  h2 {
    color: seagreen;
  }

  a {
    display: block;
  }
}

.checkout-form {
  width: 100%;
  padding: 2em;
  background-color: #fff;
  .form-group {
    text-align: left;
    &.cardnum {
      flex: 1;
      margin-right: 10px;
    }

    &.exp {
      width: 120px;
    }

    &.cvc {
      width: 80px;
      margin-left: 10px;
    }
  }

  .card-info {
    display: flex;
  }

  p {
    text-align: center;
  }

  label {
    color: #6b7c93;
    font-weight: 300;
    text-align: left;
    letter-spacing: 0.025em;
    margin-bottom: 0px;
  }

  input,
  .StripeElement {
    display: block;
    padding: 10px 14px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    // font-size: 1em;
    // font-family: "Source Code Pro", monospace;
    // box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    // border: 0;
    // outline: 0;
    // border-radius: 4px;
    background: white;

    &::placeholder {
      color: #aab7c4;
    }

    &:focus,
    .StripeElement--focus {
      box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
      -webkit-transition: all 150ms ease;
      transition: all 150ms ease;
    }
  }

  .order-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-decoration: none;
    margin-top: 5%;
    background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0.2) 4.72%,
        rgba(255, 255, 255, 0.00597804) 84.71%,
        rgba(255, 255, 255, 0.00354169) 84.71%,
        rgba(255, 255, 255, 0) 84.72%
      ),
      #679a2c;
    font-size: 1em;
    border: none;
    font-weight: bold;
    color: white;
    border-radius: 5px;
    padding: 0.5em 1em;
    width: 100%;
    transition: opacity 200ms;
    cursor: pointer;

    &.success {
      background-color: seagreen;
      border-color: seagreen;
    }

    &:hover {
      opacity: 0.8;
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  a {
    display: block;
    text-align: center;
    margin-top: 1em;
  }
}

.coupon {
  align-items: center;
  button {
    border: none;
    color: white;
    padding: 6px;
    width: 100%;
    margin-top: 8px;
    border-radius: 5px;
    background: #2e2e2e;
  }
  button:focus {
    outline: none;
  }
}
.striked {
  text-decoration: line-through;
}
