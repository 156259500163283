body {
  overflow-y: scroll;
  font-family: Roboto;
}
.container {
  width: 700px;
  &.large {
    width: 100vw;
    max-width: 100vw;
  }
  .logo {
    &.alt {
      height: 100%;
      display: flex;
      align-items: center;
    }
    img {
      width: 179px;
      height: 82px;
    }
  }
  .header {
    font-style: normal;
    font-weight: bold;
    font-size: 44px;
    line-height: 52px;
    text-align: center;
    color: #343434;
    margin-top: 24px;
  }
  .subheader {
    color: #858585;
    font-size: 18px;
    &.data-warn {
      font-size: 16px;
    }
  }
}

.step-and-provider {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  .header {
    margin-top: 0px;
  }
}

.search-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 25px;
  input:focus {
    outline-color: green;
  }
  input {
    padding: 10px;
    border: 1px solid #d6d6d6;
    padding-left: 20px;
    box-shadow: 0px 4px 7px rgb(0 0 0 / 4%);
    &.error {
      border-color: red;
      border-top: 1px solid red;
    }
  }
  input:first-child {
    border-radius: 10px 10px 0px 0px !important;
  }
  .last {
    border-top: none;
    border-radius: 0px 0px 10px 10px !important;
  }

  select {
    border-radius: 10px 10px 0px 0px;
    padding: 0px 46px 0px 20px;
    height: 48px;
    border: 1px solid #d6d6d6;
    &.error {
      border-color: red;
    }
  }
  button {
    padding: 10px;
    background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0.2) 4.72%,
        rgba(255, 255, 255, 0.00597804) 84.71%,
        rgba(255, 255, 255, 0.00354169) 84.71%,
        rgba(255, 255, 255, 0) 84.72%
      ),
      #679a2c;
    box-shadow: 0px 7px 16px rgb(114 161 59 / 22%);
    border: none;
    border-radius: 10px;
    margin-top: 25px;
    color: white;
  }
}
.upload-form {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  .title {
    margin-left: 5px;
    color: white;
    font-size: 14px;
    display: inline-block;
    width: 150px;
    font-weight: bold;
  }
  i {
    color: grey;
  }
  .uploader {
    padding: 8px 60px;
    cursor: pointer;
    margin-bottom: 30px;
    margin-top: 30px;
    background: #679a2c;
  }
  a {
    color: black;
    text-decoration: underline;
  }
}
.instructions {
  text-align: start;
  ul {
    list-style: auto;
    &.dot {
      list-style: disc;
    }
  }
  a {
    color: black;
    text-decoration: underline;
  }
}
.continue-btn {
  padding: 10px;
  text-align: center;
  font-size: 22px;
  border: navajowhite;
  border-radius: 5px;
  background: #679a2c;
  margin-top: 22px;
  color: white;
  width: 100%;
}
.list-container {
  padding: 0;
  margin: 0;
  line-height: 10px;
  margin-top: 25px;
  height: 30vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 15px;
    border-bottom: 1px solid #dedede;
    i {
      margin-right: 10px;
    }
    .provider-id {
      color: #c4c4c4;
      font-size: 18px;
    }
    .provider-title {
      color: #666666;
      font-size: 18px;
    }
    &.active {
      background: #679a2c;
      box-shadow: 0ox 11px 14px rgb(103 154 44 / 20%);
      border-radius: 10px;
      color: white;
      border-left: 7px solid #557e25;
      color: #ffffff;
      .provider-title {
        color: #ffffff;
      }
      .provider-id {
        color: #ffffff;
      }
    }
  }
  li:last-child {
    border: none;
  }
}
.map-container {
  width: 100vw;
  display: flex;
  justify-content: center;
  position: relative;
  canvas {
    width: 100%;
  }
  .help {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 250px;
    margin-top: 25px;
    .question {
      padding: 8px 14px;
      background: green;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      i {
        color: white;
        text-align: center;
      }
    }
    a {
      color: white;
      cursor: pointer;
      text-decoration: none;
    }
    a:hover {
      color: white;
    }
  }
  .buttons {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    margin-top: 25px;
    flex-direction: column;
    margin-bottom: 25px;
    font-size: 12px;
    outline: none;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
    background: white;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    .button-group {
      display: flex;
      flex-direction: column;
      margin-top: 5px;
      span {
        text-align: center;
        font-size: 14px;
        font-weight: 600;
      }
    }
    hr {
      width: 100%;
      height: 2px;
      margin-top: 15px;
      margin-bottom: 0px;
    }
    button {
      width: 100%;
      max-width: 200px;
      margin-top: 10px;
    }
    button:focus {
      outline: none;
    }
    button:first-child {
      margin-top: 0px;
    }
    .geojson {
      background: linear-gradient(
          270deg,
          rgba(255, 255, 255, 0.2) 4.72%,
          rgba(255, 255, 255, 0.00597804) 84.71%,
          rgba(255, 255, 255, 0.00354169) 84.71%,
          rgba(255, 255, 255, 0) 84.72%
        ),
        #454545;
      color: #fff;
      border-radius: 10px;
      margin-right: 10px;
      padding: 10px;
      width: 200px;
      border: none;
      &.is-drawing {
        background: #679a2c;
      }
    }
    .feature {
      border-radius: 10px;
      padding: 10px;
      padding-left: 20px;
      padding-right: 20px;
      background: white;
      border: 1px solid #00000036;
    }
  }
}
.billing-container {
  text-align: center;
  p {
    color: #8e8e8e;
  }
  .highlight {
    color: #679a2c;
  }
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f1f1f1;
    border-radius: 10px;
    padding: 25px;
    margin: 15px;
    .p1 {
      color: #808080;
    }
    .p2 {
    }
    .total {
      font-weight: bold !important;
    }
    li {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .line {
      height: 1px;
      background: #00000024;
      width: 100%;
    }
  }
  .button-container {
    padding: 15px;
    padding-top: 0px;
    button {
      width: 100%;
      padding: 12px;
      background: linear-gradient(
          270deg,
          rgba(255, 255, 255, 0.2) 4.72%,
          rgba(255, 255, 255, 0.00597804) 84.71%,
          rgba(255, 255, 255, 0.00354169) 84.71%,
          rgba(255, 255, 255, 0) 84.72%
        ),
        #679a2c;
      box-shadow: 0px 7px 16px rgb(114 161 59 / 22%);
      border-radius: 10px;
      border: none;
      color: white;
      font-weight: bold;
    }
  }
}
.modal-dialog {
  max-width: 600px !important;
}
.mapboxgl-ctrl-group.mapboxgl-ctrl {
  display: none !important;
}
.popup-hidden-toggle {
  .mapboxgl-popup-content {
    display: none !important;
  }

  .mapboxgl-ctrl-group.mapboxgl-ctrl {
    display: initial !important;
  }
  .mapboxgl-popup-tip {
    display: none !important;
  }
}
.popup-hidden {
  .mapboxgl-popup-content {
    display: none !important;
  }

  .mapboxgl-ctrl-group.mapboxgl-ctrl {
    display: initial !important;
  }
  .mapboxgl-popup-tip {
    display: none !important;
  }
}

.hidden {
  display: none !important;
}
.spinner {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #444444;
  width: 18px;
  height: 18px;
  margin-left: 75px;
  animation: spin 0.5s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.confirmation {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 80vh;
  button {
    border: none;
    background: #679a2c;
    width: 80px;
    color: white;
    border-radius: 5px;
    padding: 4px;
  }
}
.popup-main {
  max-height: 360px;
  width: 320px;
  overflow: hidden;
  padding: 0;
  .page {
    overflow: auto;
    height: 400px;
    padding: 0 20px 20px 20px;
    border: solid 0px #f00;
  }
}
.popup-pagination {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin-top: 10px;
  margin-right: 20px;
  li:first-child {
    padding-right: 10px;
  }
  a {
    color: black;
    font-size: 15px;
  }
}
.mapboxgl-popup {
  max-width: 290px !important;
}
// .map-container {
//   .mapboxgl-popup {
//     visibility: none;
//     &:nth-child(1) {
//       display: flex;
//     }
//   }
// }
@media screen and (max-height: 995px) {
  .map-container .buttons {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: calc(100vh - 210px);
  }
}

.choices {
  display: flex;
  .choice {
    cursor: pointer;
    border: solid 3px #d1d1d1;
    width: 50%;
    height: 150px;
    padding: 10px;
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }

    &.selected {
      border: #679a2c;
      background: #679a2c;
      color: #fff;
      i {
        color: #fff;
      }
    }

    .icon-set {
      i {
        font-size: 30px;
        // color: #679a2c;
        &:nth-child(2) {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
  }
}

.button {
  padding: 10px;
  background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.2) 4.72%,
      rgba(255, 255, 255, 0.00597804) 84.71%,
      rgba(255, 255, 255, 0.00354169) 84.71%,
      rgba(255, 255, 255, 0) 84.72%
    ),
    #679a2c;
  background: #679a2c;
  box-shadow: 0px 7px 16px rgb(114 161 59 / 22%);
  border: none;
  border-radius: 10px;
  margin-top: 25px;
  color: white;
}

.home-help {
  margin-top: 10px;
  a {
    color: black;
    cursor: pointer;
    text-decoration: none;
  }
}

.upload-header {
  font-size: 20px;
}
.upload-format {
  font-size: 0.8rem;
  font-weight: 500;
  .upload-format-link {
    text-decoration: underline;
    color: black;
  }
}
.processing {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}
.results {
  margin-left: 100px;
  .price-breakdown {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 110px;
    li {
      width: 50%;
      display: flex;
      justify-content: space-between;
    }
    .rate {
      font-weight: bold;
    }
    button {
      border: none;
      background-color: #679a2c;
      width: 160px;
      color: white;
      padding: 3px;
      margin-top: 20px;
    }
  }
}

.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    width: 650px !important;
    text-align: center;
  }
}
